<template>
  <div id="app" class="bg">

    <nav class="navbar navbar-dark navbg navbar-expand-lg">
      <a class="navbar-brand mb-0 h1 navheader">Information Theory and Statistics Project</a>
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto my-2 my-lg-0">
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#about"> About </a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#theory"> Theory </a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#encoder"> Encoder </a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#decoder"> Decoder </a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger" href="#examples"> Examples </a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger nav-primary" href="/ITS_Project.pdf" download> Report </a>
          </li>
          <li class="nav-item">
            <a class="nav-link js-scroll-trigger nav-primary" href="https://gitlab.com/enschedelly/its-lempel-ziv" target="_blank"> GitLab </a>
          </li>
        </ul>
      </div>
    </nav>

    <div id="about" class="container padding mt-4">
      <h4>About the project</h4>
      <p>
        On this page I present the project I have created for the course Information Theory and Statistics.
        For this project I have chosen the subject of data compression with as specific subject Lempel-Ziv coding.
        This project consists of two parts. The first part is a practical part where I demonstrate the functioning of
        this coding technique and explain step by step what happens during the compression and decompression
        phases. This part can be found on this page. For demonstration purposes I limited the character set to all
        26 upper and lower case letters, spaces, commas, apostrophes, and full stops. In the second part I explain the
        working of this algorithm in detail and explain more about the theory behind this technique. Here I also compare
        the effectiveness of this algorithm to other commonly used compression algorithms. This second part can be
        found by pressing the 'report' button on the top right of this page. This project has been graded with a 9.0!
      </p>
    </div>

    <div id="theory" class="container padding">
      <h4>Lempel-Ziv coding</h4>
      <p>
        Lempel-Ziv Welch coding is a lossless data compression algorithm which is now widely implemented and used.
        The algorithm was created by Abraham Lempel, Jacob Ziv, and Terry Welch in 1984.
        Lempel-Ziv Welch coding is a variation on the Lempel-Ziv coding introduced in 1978 and is a lossless dictionary based compression algorithm.
        The functioning of this algorithm is very different from other compression methods that are based on stream
        codes or symbol codes. Lempel-Ziv coding makes use of dictionaries to store substrings of characters that have
        occurred before in the text, thus 'memorizing' them. This method thus relies heavily
        on repetition. Although it can achieve great compression, this way of encoding can also increase the total
        amount of bits needed for transmission, as the dictionary grows and adds additional bits to the code.
        Welch created this variant on the Lempel-Ziv coding to build on the original philosophy and improve
        the compression rate even more. This way of compression was designed to solve issues regarding poor runtime
        execution speed, inflexibility towards redundancy, and storage space management problems due to unpredictable
        block lengths. It makes use of the 'greedy' parsing algorithm, where the text is looped over exactly once.
        Although this algorithm can achieve great compression, it does not attempt to
        optimally select strings by making use of probability estimation. Therefore, its effectiveness is less than
        optimal, but creates great usability by the simplicity of the algorithm.
      </p>
    </div>

    <div id="encoder" class="container padding">
      <h4>Encoder</h4>
      <p>
        Here you can test the encoder. please input a piece of text and you will be guided through the
        compression process.
      </p>
      <form @submit.prevent="encode">
        <div class="form-group">
          <textarea v-model="encodeText" class="form-control bg-light bg-opaque" id="encodingForm" rows="3"></textarea>
        </div>
        <button class="btn btn-primary mb-2">Compress</button>
      </form>
    </div>

    <div class="container padding" v-if="encodingActive">
      <div>
        <h5>Encoded Text:</h5>
        <code>{{ encodeResult }}</code>
      </div>
      <div v-if="!encodeError">
        <div>
          <p class="mt-3">We were able to create a code with <code>{{ bitsWith }}</code> bits.</p>
        </div>
        <div>
          <p class="mt-3">Without compression this text would have needed <code>{{ bitsWithout }}</code> bits.</p>
        </div>
        <div v-if="compressed">
          <p class="mt-3">Which means we were able to compress the file by <code>{{ percentageCompression }}%</code>.</p>
        </div>
        <div v-if="!compressed">
          <p class="mt-3">This algorithm was not able to efficiently compress your text. The total compression rate is <code>{{ percentageCompression }}%</code>.</p>
        </div>
        <div>
          <p>Now let's see how we got these results.</p>
          <p>To begin with, our algorithm needs to have a base set with known characters. Commonly for computers this
            base set is UTF-8, but ours consists of all 26 upper and lower case letters, spaces, commas, apostrophes,
            full stops, and breaks. These are a total of 57 characters. All these characters have a binary representation,
            depending on where in this set they are located. The first character in this set is 'a', which thus gets
            the binary representation '000000'. The letter 'f' is the sixth, so it is represented by '000101', which
            is 6 in binary. The last character in this base set is the break, which is represented by '111000'.
          </p>
          <p>Using this representation we can already encode the entire text into its binary representation. However,
            by doing so it is still quite lengthy. As you can also see above, encoding the text using only these
            representations would give us a total of <code>{{ bitsWithout }}</code> bits. Most of the times we can make this more
            efficient. Especially in pieces of text where there is a lot of repetition, we can achieve good compression.
          </p>
          <p>So how can we do this? The algorithm reads through the text from left to right, just like
            we humans do. To start, the first character is always encoded in the way
            it is represented in the character set. In this example we have '<code>{{ firstLetter }}</code>' which is represented by
            '<code>{{ firstBinary }}</code>'. Then it looks at the next character, in this case '<code>{{ secondLetter }}</code>'
            and adds the combination '<code>{{ firstLetter + secondLetter }}</code>' to the base table. Since it is the
            first combination added to the set it gets represented by '111001'. Be aware that leading zeros do not change
            the number. In your case it might get represented by '00111001'. The spaces in the output are merely there
            for readability, the computer needs to rely on a fixed code length. When we need to add the representation
            '1000' we need to alter '111' to '0111'. Now that the first character is encoded and the
            first combination is added to the set, the algorithm moves to the second character '<code>{{ secondLetter }}</code>'
            and verifies if the combination with the third character '<code>{{ secondLetter + thirdLetter }}</code>' is
            already in the character set. If so, it adds the fourth character to the string for evaluation and checks again.
            As soon as the combination of characters is not in the table it stores it and uses the binary representation
            of the longest found combination. By doing this repetitively, it can encode and compress the entire text into
            its binary representation.
          </p>
        </div>
        <div v-if="hasRepetition">
          <p> To grab an example from the text you put into the text box, let us look at the first occurrence of a sequence
            of three characters added to the set. In your text, this is the combination '<code>{{ firstRepetition }}</code>'.
            You can verify this yourself by going through the text and making the letter combinations as described in
            the explanation above. Here you should see that the combination '<code>{{ firstRepetition[0] + firstRepetition[1] }}</code>'
            occurs in your text before '<code>{{ firstRepetition }}</code>' does. Remember that for a computer breaks and
            spaces are also characters, so your first triple might look a bit odd for you. Since a space is the most
            frequently occurring character chances are high it is included in this triple. Also be aware that this
            algorithm does only store one additional new character at a time. When your text says 'bla bla bla' it
            will not directly input 'bla_' as a character sequence, but first start with 'bl', then 'la', 'a_', and, '_b'
            before it gets to add 'bla' to the character set.
          </p>
        </div>
        <div v-if="!hasRepetition">
          <p> We have now covered the basic principles of this compression algorithm. If you input a larger piece of
            text we can evaluate how the repeating part of the algorithm applies to that text. Try it out
            with the song text below to get more examples!
          </p>
        </div>
        <div>
          <p> Now why all this hassle to input these sequences piece by piece when we can just add frequently occurring
            words to the set at one go? Why not add 'and', 'then', 'we' and such words to the set of characters in advance?
            Or read through the text and add some words in its entirety to the set? The main reason why this is not done,
            is because at the end it will not result in a better compression. Of course the text itself can be compressed
            more than it could be now, but then the decoder won't be able to make sense of it. We would be obliged to
            send some sort of dictionary with it so that our decoder knows how to interpret the sequences representing
            'and', 'then', and 'we'. You can imagine that adding such a dictionary eventually adds more load to the
            transmission than it saved.
          </p>
          <p> So enough on the encoder. Let's move on to the decoder, see how that works in detail, and find out why it
            does not need a dictionary to decompress the input. You can test the decoder either by copying the result
            you received from this section into the input field, or by copying the binary at the bottom of the page.
          </p>
        </div>
      </div>
    </div>

    <div id="decoder" class="container padding">
      <h4>Decoder</h4>
      <p>
        Here you can test the decoder. please input a piece of text and you will be guided through the
        decompression process.
      </p>
      <form @submit.prevent="decode">
        <div class="form-group">
          <textarea v-model="decodeBits" class="form-control bg-light bg-opaque" id="decodingForm" rows="3"></textarea>
        </div>
        <button class="btn btn-primary mb-2">Decompress</button>
      </form>
    </div>

    <div class="container padding" v-if="decodingActive">
      <div>
        <h5>Decoded Text:</h5>
        <code style="white-space: pre-line;">{{ decodeResult }}</code>
      </div>
      <div v-if="!decodeError">
        <div class="mt-3">
          <p>So now that we know how to encode and compress the text, lets see how we could then decode and decompress that
            text back into its human readable representation. The way of decoding is actually very similar to the way of
            encoding. Again, we start with a base set of characters; the same set as we had for encoding. This is important,
            since we do not want to load the net with this 'dictionary', as explained. All computers already know the
            agreed upon base set and can decode the incoming bit string accordingly. But how does this work?
          </p>
          <p>As with the compression, decompression also begins with only the 57 main characters in its base set, and
            as with the compression algorithm, it will expand this character set while it is decoding the bit string.
            So just like the encoding algorithm, the decoding algorithm also starts with translating the first sequence
            of bits. Since this first sequence is always present in the base set, it can look this sequence up and
            add the corresponding character to the result.
          </p>
          <p>Now there is two ways to go. Either the algorithm does not recognize the next bit sequence, or it does. Now when
            it does recognize this bit sequence it saves the recognized character in the store and pushes it to the
            result. Now in order to create and store longer character sequences, as was also done in the encoding algorithm,
            it takes the first character out of the recognized bit sequence and also saves this. In case that the character
            sequence is actually just a single character, it saves that character. This first character will
            also be used when an unknown bit sequence is encountered. To expand the character set it adds the character
            string recognized from the previous step, plus this first character to the character set. Since this new
            combination will always be added to the end of this set, its index represents its bit sequence.
          </p>
          <p>Now that the character sequence is in the result, it can continue to the next bit sequence. Now, if it does not
            recognize this bit sequence, it saves a different value to the store. First, it takes the character sequence from
            its last step and adds the first character it has stored from the previous step to it, like was also done at the
            end of the previous section. Now this character combination is the actual result and will be pushed to the
            results. Now it follows the same steps as were also taken when the sequence was recognized, so create a longer
            character sequence, store this to the character set, and move on to the next bit sequence.
          </p>
          <p>In order to explain the algorithm better and show you some examples, let's have a look at the binary sequence
            that has been decoded just now. In this example, '<code>{{ firstCharDecode }}</code>' is the first character.
            You can verify this yourself by looking at the binary representation of this character. Its binary
            representation is <code>{{ firstBinaryChar }}</code>, which in a human readable decimal is the number
            <code>{{ firstBinaryNumber }}</code>. Now if you write down the character set as described before and count
            until you encounter '<code>{{ firstCharDecode }}</code>', you will see that it is indeed at this spot. Do note
            that you need to start to count from 0, not 1.
          </p>
          <p>So we've got our first character decoded. Now how do we continue? As explained before, after the first
            character has been decoded the algorithm starts with taking additional steps to expand the character set
            and include the combinations of characters that have been encountered during decoding. Now as explained, at
            this point the decoder either does or does not recognize the next bit sequence as a known character. Depending
            on that it either saves the current character to the store, or adds the previous character plus the 'first
            character' respectively. Since also the second character is always known, we will take that path to explain
            all the steps. In the binary sequence, the second binary representation is <code>{{ secondBinaryChar }}</code>
            which represents the character '<code>{{ secondCharDecode }}</code>'. This value is now added to the store,
            and the character is pushed to the result. Now separately from the store, it also saves the first character
            of '<code>{{ secondCharDecode }}</code>', which is in this case simply '<code>{{ secondCharDecode }}</code>'.
            Then to expand the character set, it takes the previously decoded character '<code>{{ firstCharDecode }}</code>',
            adds the current character '<code>{{ secondCharDecode }}</code>' and adds the combination
            '<code>{{ firstCharDecode + secondCharDecode }}</code>' to the character set. Now all steps have been taken
            and the algorithm moves on to the next binary sequence.
          </p>
        </div>
      </div>
    </div>

    <div id="examples" class="container padding">
      <h4>Examples</h4>
      <p>
        To test the algorithm and get a more elaborate explanation on how it works, you can copy the text below
        into the text boxes of the compression and decompression section accordingly. You could also input your
        own piece of text. In this case make sure to only use letter, spaces, apostrophes, commas, and full stops
        in your text.
      </p>
      <div class="row mt-4 pt-2">
        <div class="col-md-6">
          <h6><b>Example Compression Text</b></h6>
          <code>
            We're no strangers to love<br>
            You know the rules and so do I<br>
            A full commitment's what I'm thinking of<br>
            You wouldn't get this from any other guy<br>
            I just wanna tell you how I'm feeling<br>
            Gotta make you understand<br><br>
            Never gonna give you up<br>
            Never gonna let you down<br>
            Never gonna run around and desert you<br>
            Never gonna make you cry<br>
            Never gonna say goodbye<br>
            Never gonna tell a lie and hurt you<br><br>
            We've known each other for so long<br>
            Your heart's been aching but you're too shy to say it<br>
            Inside we both know what's been going on<br>
            We know the game and we're gonna play it<br>
            And if you ask me how I'm feeling<br>
            Don't tell me you're too blind to see<br><br>
            Never gonna give you up<br>
            Never gonna let you down<br>
            Never gonna run around and desert you<br>
            Never gonna make you cry<br>
            Never gonna say goodbye<br>
            Never gonna tell a lie and hurt you<br><br>
            Never gonna give you up<br>
            Never gonna let you down<br>
            Never gonna run around and desert you<br>
            Never gonna make you cry<br>
            Never gonna say goodbye<br>
            Never gonna tell a lie and hurt you<br>
            Never gonna give, never gonna give<br>
            Give you up<br><br>
            We've known each other for so long<br>
            Your heart's been aching but you're too shy to say it<br>
            Inside we both know what's been going on<br>
            We know the game and we're gonna play it<br>
            I just wanna tell you how I'm feeling<br>
            Gotta make you understand<br><br>
            Never gonna give you up<br>
            Never gonna let you down<br>
            Never gonna run around and desert you<br>
            Never gonna make you cry<br>
            Never gonna say goodbye<br>
            Never gonna tell a lie and hurt you<br><br>
            Never gonna give you up<br>
            Never gonna let you down<br>
            Never gonna run around and desert you<br>
            Never gonna make you cry<br>
            Never gonna say goodbye<br>
            Never gonna tell a lie and hurt you<br><br>
            Never gonna give you up<br>
            Never gonna let you down<br>
            Never gonna run around and desert you<br>
            Never gonna make you cry<br>
            Never gonna say goodbye
          </code>
        </div>
        <div class="col-md-4 sm-mt">
          <h6><b>Example Decompression Binary Code</b></h6>
            <code>
              00110000 00001000 00010011 00000111 00110100 00111011 00000100 00110100 00000001 00010001 00000100 00000000 00111011 00001000 00001101 00000110 00110100 00010010 00000100 00010010 00010010 00001000 00001110 00001101 00110100 00100010 00111101 00000111 00001110 00010100 00000110 00000111 00010011 01010001 00110100 00000111 00000000 00000011 00111101 00001110 00110100 00010110 01000100 00000010 00111100 00001100 00011000 00110100 00000010 00001110 00001011 01010101 00010001 00010010 01101000 01011100 01000111 00000100 00110110 01001001 01100000 00001100 00000000 00011000 00000001 00111111 01010010 01100010 01101110 01000101 00001101 00001010 01000110 01001000 00010011 00001110 01110101 00010100 01100100 00110100 00000011 00010100 00010001 10000001 01000000 01000010 01000100 00000111 10000001 00110101 01011010 01100010 00001101 00010011 00000100 01011110 10000011 01001001 00000100 00111111 00010110 00000111 00000100 00111110 00010001 01011010 01101001 00010100 00001011 01011110 01000110 00000101 00001011 00010100 00000100 00001101 00000010 00111111 00111110 01101000 01101010 01101100 01000000 00000100 00000010 00000000 00010100 01001010 00110100 00001110 00000101 00110100 01100110 10001000 00001000 00000101 00000101 00000100 01000010 10101100 00111111 01000110 10001101 01000011 01111110 00000110 01110011 00010010 01100000 00111011 01000100 10111100 00001000 01010111 01011001 01011100 00010101 00111111 00000000 11000000 10110100 10010110 10011000 10011110 10111001 00010100 00010011 01101001 00001100 11010110 00110100 00001011 00111010 00010011 00001011 00111111 00000001 00111010 00110101
            </code>
        </div>
        <div class="col-md-2">
          <!--  Empty on purpose :) Ugly but pretty quick fix  -->
        </div>
      </div>
    </div>


    <footer class="navbg py-4 mt-5">
      <div class="container">
        <div class="row">
         <div class="col">
           <a class="navbar-brand h1 href" href="https://enschedelly.nl">Enschedelly</a>
         </div>
          <div class="col small text-muted mt-2 text-right">
            &copy; {{ new Date().getFullYear().toString() }} - Nikki Zandbergen
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>





<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'App',
  data() {
    return {
      encodingActive: false,
      decodingActive: false,
      encodeText: "",
      decodeBits: "",
      characterSetBase: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z", " ", ".", ",", "'", "\n"],
      encodeResult: "",
      decodeResult: "",
      bitsWith: null,
      bitsWithout: null,
      compressed: null,
      firstLetter: "",
      firstBinary: "",
      secondLetter: "",
      thirdLetter: "",
      hasRepetition: null,
      firstRepetition: "",
      percentageCompression: null,
      firstCharDecode: "",
      secondCharDecode: "",
      firstBinaryChar: "",
      secondBinaryChar: "",
      firstBinaryNumber: "",
      encodeError: true,
      decodeError: true
    }
  },
  methods: {
    encode() {
      this.bitsWith = null;
      this.bitsWithout = null;
      this.firstLetter = "";
      this.firstBinary = "";
      this.secondLetter = "";
      this.thirdLetter = "";
      this.hasRepetition = null;
      this.percentageCompression = null;
      if (this.hasEncodeError('a', ['a'])) return;
      let characterSet = cloneDeep(this.characterSetBase);
      let encodedBinary = [];
      let result = [];
      let current = this.encodeText[0];
      this.firstLetter = current;
      this.secondLetter = this.encodeText[1];
      this.thirdLetter = this.encodeText[2];
      for (let index = 0; index < this.encodeText.length; index++) {
        let next = this.encodeText[index + 1] ? this.encodeText[index + 1] : current ;
        if (characterSet.includes(current + next)) {
          current = current + next;
        } else {
          if (this.hasEncodeError(current, characterSet)) return;
          encodedBinary.push(characterSet.indexOf(current).toString(2));
          characterSet.push(current + next);
          current = next;
        }
      }
      let bits = Math.ceil(Math.log2(characterSet.length));
      for (let binary of encodedBinary) {
        if (binary.length < bits) {
          binary = Array(bits - binary.length + 1).join("0") + binary;
        }
        result.push(binary);
      }
      this.encodeResult = result.join(" ");
      this.bitsWith = this.getBitsWith(this.encodeResult);
      this.bitsWithout = this.getBitsWithout(this.encodeText.length, this.characterSetBase);
      this.percentageCompression = this.getPercentageCompression();
      this.firstBinary = result[0];
      this.hasRepetition = this.checkRepetition(characterSet);
      this.encodeError = false;
      this.encodingActive = true;
    },
    hasEncodeError(character, characterSet) {
      let error;
      if (this.encodeText.length <= 1) {
        this.encodeResult = "Please enter a longer text to encode."
        error = true;
      }
      if (!characterSet.includes(character)) {
        this.encodeResult = "Woops! The text contains an invalid character."
        error = true;
      }
      if (error) {
        this.encodingActive = true;
        this.encodeError = true;
        return true;
      }
      return false;
    },
    getBitsWith(encodeResult) {
      let count = 0;
      for (let i = 0; i < encodeResult.length; i++) {
        if(encodeResult[i] === "0" || encodeResult[i] === "1") count++;
      }
      return count;
    },
    getBitsWithout(textLength, characterSetBase) {
      let bits = Math.ceil(Math.log2(characterSetBase.length));
      return bits * textLength;
    },
    getPercentageCompression() {
      let percentage = -((this.bitsWith - this.bitsWithout) / this.bitsWithout * 100).toFixed(2);
      this.compressed = (percentage > 0)
      return percentage;
    },
    checkRepetition(characterSet) {
      for(let i = 56; i < characterSet.length; i++) {
        if(characterSet[i].length > 2) {
          this.firstRepetition = characterSet[i];
          return true;
        }
      }
      return false;
    },
    hasDecodeError(character) {
      if (!this.decodeBits) {
        this.decodeResult = "Please enter something to decode."
        this.decodingActive = true;
        this.decodeError = true;
        return true;
      }
      let error = false;
      character.split('').forEach(number => {
        if (number !== '0' && number !== '1') {
          this.decodeResult = "Woops! There is something wrong with this binary representation.";
          this.decodeError = true;
          this.decodingActive = true;
          error = true;
        }
      })
      return error;
    },
    decode() {
      if (this.hasDecodeError("")) return;
      let characterSet = cloneDeep(this.characterSetBase);
      let binaryCharacters = this.decodeBits.split(" ");
      console.log(binaryCharacters[0]);
      if (this.hasDecodeError(binaryCharacters[0])) return;
      let result = [];
      let prevIdx = parseInt(binaryCharacters[0], 2)
      let store;
      let character;
      result.push(characterSet[prevIdx]);
      this.firstCharDecode = characterSet[prevIdx];
      this.firstBinaryChar = binaryCharacters[0];
      this.firstBinaryNumber = prevIdx;
      this.secondBinaryChar = binaryCharacters[1];
      this.secondCharDecode = characterSet[parseInt(binaryCharacters[1], 2)];
      for (let current = 1; current < binaryCharacters.length; current++) {
        if (this.hasDecodeError(binaryCharacters[current])) return;
        let currentIdx = parseInt(binaryCharacters[current], 2);
        if (!characterSet[currentIdx]) {
          store = characterSet[prevIdx] + character;
        } else {
          store = characterSet[currentIdx];
        }
        result.push(store);
        character = store[0];
        characterSet.push(characterSet[prevIdx] + character)
        prevIdx = currentIdx;
      }
      this.decodeResult = result.join("");
      this.decodeError = false;
      this.decodingActive = true;
    }
  }


}
</script>

<style lang="scss">
@import "assets/scss/app.scss";

  #app { }

  body {
    color: rgba(255, 255, 255, 0.8);
  }

  p {
    text-align: justify;
  }

  .navheader {
    padding: 1rem;
  }

  .navbg {
    background-color: #262626;
    padding-right: 2rem;
  }

  .bg-opaque {
    opacity: 0.8;
  }

  .bg {
    background-color: #333;
  }

  .padding {
    padding-top: 2rem;
  }

  .btn-pink {
    background-color: #e83e8c !important;
    border-color: #e83e8c !important;
  }

  .nav-primary {
    color: #e83e8c !important;
  }

  .nav-primary:hover {
    color: #e884b2 !important;
  }

  .href {
    font-weight: bold;
    color: rgba(255, 255, 255, 0.7);
  }

  .href:hover {
    color: rgba(255, 255, 255, 0.9)
  }

  .sm-mt {
    margin-top: 0;
  }

  @media (max-width: 768px) {
    .sm-mt {
      margin-top: 3rem;
    }
  }


</style>
